import React, { useEffect, useRef, useState } from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { H6, TextSm } from '@system'
import isInBrowser from '@helpers/is-in-browser'
import debounce from 'lodash/debounce'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 2,
    padding: '16px 0px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 20px 0px',
      width: '33.33%',
      margin: (props) => `-${props.imageHeight + 16}px auto 0`, // This aligns all of the images to the color transition within the module
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      padding: '16px 10px 0px',
    },
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  content: {
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    display: 'block',
  },
  description: {
    color: theme.palette.text.tertiary,
    textAlign: 'center',
    padding: '8px',
    display: 'block',
    [theme.breakpoints.between('sm', 'sm')]: {
      minHeight: '200px',
    },

    [theme.breakpoints.up('md')]: {
      minHeight: '150px',
    },
  },
  ctaContainer: {
    padding: '0 0 1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0',
    },
  },
  noCta: {
    display: 'none',
  },
}))

const CustomerTypeCard = (props) => {
  const [imageHeight, setImageHeight] = useState(0)
  const classes = useStyles({ ...props, imageHeight })
  const imageRef = useRef()

  useEffect(() => {
    if (isInBrowser && !!imageRef.current) {
      window.addEventListener(
        'resize',
        debounce(() => {
          setImageHeight(imageRef.current.offsetHeight)
          props.updateMaxOverlap(imageRef.current.offsetHeight)
        }, 100)
      )
      setImageHeight(imageRef.current.offsetHeight)
      props.updateMaxOverlap(imageRef.current.offsetHeight)
    }
  }, [imageRef])

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.card}>
        <Box ref={imageRef} className={classes.image}>
          {renderBloks(props.blok.image)}
        </Box>
        <Box className={classes.content} id="cardContent">
          <H6 className={classes.name}>{props.blok.name}</H6>
          <TextSm className={classes.description}>
            {props.blok.description}
          </TextSm>
          <Box
            className={classNames(classes.ctaContainer, {
              [classes.noCta]: !!props.blok.cta && !props.blok.cta.length,
            })}
          >
            {renderBloks(props.blok.cta)}
          </Box>
        </Box>
      </Box>
    </SbEditable>
  )
}

export default CustomerTypeCard
